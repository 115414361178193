@use "../utils/mixins";
@use "../utils/variables";

.profile-head__container {

    @include mixins.breakpoint() {

        height: 100%;

        display: flex;
        flex-direction: column;

    }
    @include mixins.breakpoint(variables.$small_tablet) {

        height: calc(100% - 40px);

        display: flex;
        flex-direction: row;
        padding-right: 10px;

    }
    @include mixins.breakpoint(variables.$small_desktop) {

        width: 1230px;
        margin: 0 auto;
        padding-right: 0;
    }

    .profile__details {

        &.trial{
          margin: auto;
          margin-top: 20px;
          text-align: center;
          @include mixins.breakpoint(variables.$small_tablet) {
            text-align: left;
            margin: auto;
          }
        }
        @include mixins.breakpoint() {
            flex-grow: 1;

            display: flex;
            flex-direction: column;
            justify-content: center;

            padding: 0 20px;
        }
        @include mixins.breakpoint(variables.$small_tablet) {

            flex: none;
            width: 65%;
            text-align: left;

            padding: 0 0 0 60px;

        }
        @include mixins.breakpoint(variables.$small_desktop) {
            padding: 0 0 0 40px;
        }

        .profile__name {

            @include mixins.breakpoint() {

                font-family: 'National Book',sans-serif;
                color: variables.$white;

                font-size: 35px;
                line-height: 1;
                font-weight: variables.$regular;
                
                margin: 0 0 10px 0;

            }
            @include mixins.breakpoint(variables.$small_tablet) {
                font-size: 53px;
            }
            @include mixins.breakpoint(variables.$small_desktop) {
                font-size: 66px;
            }
        }

        .profile__action {
            @include mixins.breakpoint() {

                width: 190px;

            }

            &.trial {
              margin: auto;
              margin-top: 0px;
              @include mixins.breakpoint(variables.$small_tablet) {
                margin: 0;
              }
            }

            @include mixins.breakpoint(variables.$small_tablet) {
              margin: 0;
            }

        }

      .profile__ghin {
        @include mixins.breakpoint() {
          color: #fff;
          font-size: 14px;
          line-height: 1;
          font-weight: 300;
          margin-top: -10px;
          margin-bottom: 15px;
        }
        @include mixins.breakpoint(variables.$small_desktop) {
          font-size: 18px;
          margin-top: -20px;
          margin-bottom: 20px;
        }
      }

      .profile__trial_expired{
        color: red;
        font-weight: bold;
        margin-top: 5px;
      }
    }

    .profile__memberships {
        @include mixins.breakpoint() {
            display: none;
        }
        @include mixins.breakpoint(variables.$small_tablet) {
            display: block;
            align-self: center;
            background-color: rgba(255, 255, 255, 0.85);
            padding: 15px 25px;
            width: 280px;
            margin: 40px auto 0;
            max-height: 250px;
            overflow-y: auto;
            h4, h3{
                font-size: 16px;
                font-weight: variables.$semi_bold;
                color: variables.$dark_midnight_blue;
                text-transform: uppercase;
                letter-spacing: 2px;
                margin-bottom: 8px;
            }
            ul{
                list-style: none;
                li{
                    padding: 10px 0;
                    font-size: 15px;
                    font-weight: variables.$regular;
                    line-height: 1em;
                    border-bottom: 1px solid rgba(variables.$black, 0.25);
                    color: rgba(variables.$black, 0.9);
                    letter-spacing: 0.5px;
                    .assoc{
                        font-size: 15px;
                        color: rgba(variables.$black, 1);
                        margin-bottom: 1px;
                    }
                    .club{
                        font-size: 14px;
                        color: rgba(variables.$black, 0.7)
                    }
                    &:last-child{
                        border-bottom: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }
        @include mixins.breakpoint(variables.$small_desktop) {
            padding: 20px 30px 15px;
            width: 340px;
            max-height: 292px;
            h4, h3{
                font-size: 20px;
                margin-bottom: 10px;
            }
            ul{
                li{
                    padding: 12px 0;
                    font-size: 16px;
                }
            }
        }

        //  FIXES AND OVERRIDES
        ul {
          li {
            @include mixins.breakpoint(variables.$small_tablet) {
              display: flex;
              flex-direction: row;
            }
            .club-logo__container {
              .club-logo {
                @include mixins.breakpoint(variables.$small_tablet) {

                  height: 34px;
                  width: auto;
                  max-width: 34px;

                  margin: 0 10px 0 0;

                  display: block;
                  object-fit: contain;

                }
              }
              &:empty {
                & + .club__details {
                  @include mixins.breakpoint(variables.$small_tablet) {
                    margin-left: 44px;
                  }
                }
              }
            }
            .club__details {
              .assoc {
                @include mixins.breakpoint(variables.$small_tablet) {
                  margin-bottom: 2px;
                }
              }
              .club {

              }
            }
          }
        }

    }

}