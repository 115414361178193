// General styles for the table
.position-cell {
  display: flex;
  align-items: center;
}

.position-display {
  width: 50%;
}

.follow-icon {
  width: 50%;
}

// Icons
.icon-star,
.icon-star-outline {
  color: rgb(0, 54, 95);
}

// Header styles
.header-round,
.header-total {
  font-family: "National Book", sans-serif;
  font-size: 12px !important;
  font-weight: bold;
  color: gray !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.header-position {
  font-family: "National", sans-serif;
  font-size: 12px !important;
  font-weight: bold;
  color: rgba(16, 24, 40, 1) !important;
  padding-right: 0 !important;
}

.header-name {
  font-family: "National", sans-serif;
  font-size: 12px !important;
  font-weight: bold;
  color: rgba(16, 24, 40, 1) !important;
}

// Body cell styles for each column
.body-round,
.body-total {
  border-left: 1px solid rgb(224, 224, 224);
  justify-content: center;
}

// Name cell styles
.name-cell {
  font-family: "National", sans-serif;
  font-size: 16px;
  color: #000000;
  font-weight: 600;
  max-width: 100%;
  display: flex;
  align-items: center;
}

.name-display {
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
}

.handicap-display {
  margin-left: 10px;
  font-family: "National Book", sans-serif;
  font-size: 18px;
  color: gray;
}

// Round cell styles (R1, R2, R3)
.round-cell {
  font-family: "National Narrow", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: rgba(102, 112, 133, 1);
}

// Total column styles
.total-positive {
  font-weight: bold;
  // width: 100%;
  // text-align: center;
  // display: inline-block;
}

.total-negative {
  font-weight: bold;
  color: red;
}

.MuiBox-root.css-1tbggly {
  display: none !important;
}
@media (max-width: 600px) {
  .name-cell,
  .handicap-display,
  .icon-star,
  .icon-star-outline {
    font-size: 0.8em !important;
  }

.total-negative,
.total-positive,
.round-cell {
  font-size: 12px;
}

.position-cell {
  font-size: 16px;
}

  .header-name,
  .header-position,
  .header-round,
  .header-total {
    font-size: 12px !important;
    font-family: "National" !important;
  }
}
